import { Close16, Delete16, Edit16, Save16 } from "@carbon/icons-react"
import { Button } from "carbon-components-react"
import classNames from "classnames"
import { OrchestrationFormConfigCallBack } from "modules/routingRules/types"
import { createEditAttributeValidation } from "modules/routingRules/validation"
import { useRef, useState } from "react"
import { useTrans } from "system/translations/hooks"
import ChangeValueDialogWindow from "../ChangeValueDialogWindow"
import Input from "../Input"

const PaymentOrchestrationTreeViewItem: React.FC<{
  title?: string,
  isDeleted?: boolean,
  parametrs?: any,
  data?: any
  isEdit?: boolean,
  isAuthType?: boolean,
  type: string,
  onRemove?: any,
  onUpdate?: any 
  onClearValues?: any ,
  getOrchestartionFormConfig: OrchestrationFormConfigCallBack
  onTitleChange?: (newTitleValue: string) => void
}> = ({
  title = 'Attribute 1: Auth type',
  isDeleted = true,
  parametrs = "",
  data = [],
  isEdit = true,
  isAuthType = false,
  type,
  onRemove,
  onUpdate,
  onClearValues,
  getOrchestartionFormConfig,
  onTitleChange
}) => {
  const { _t } = useTrans()
  const formConfig = getOrchestartionFormConfig(type, data)
  const validation = createEditAttributeValidation(_t, type)
  const [ isEditAttributeName, setIsEditAttributeName ] = useState(false)
  const [ changedTitle, setChangedTitle] = useState(title)
  const cellRef = useRef<any>(null)

  const submitButtonHandle = () => {
    if ((changedTitle !== title) && onTitleChange) {
      onTitleChange(changedTitle)
    }
    setIsEditAttributeName(false)
  }


  const iconOnHover = (ref: any) => {
    if (ref.current) {
      ref.current.style.visibility = 'visible'
    }
  }
  const iconOnLeave = (ref: any) => {
      if (ref.current) {
        ref.current.style.visibility = 'hidden'
      }
  }

  const isEditableTitleName = type === 'condition' || type === 'conditionGroup'
  
  return (
    <div className={classNames("tree-view-item", type === 'authType' && "tree-view-item-authType")}>
      <div className="tree-view-item__top" onDoubleClick={() => !isEditAttributeName && setIsEditAttributeName(true)}>
        {isEditAttributeName && isEditableTitleName
          ? <div className="bx--search-wrapper tree-view-item__change-title">
            <Input value={changedTitle} onChange={(e) => setChangedTitle(e)} />
            <Button
              style={{ color: "#000" }}
              className={"bx--search-button"}
              type={"submit"}
              hasIconOnly={true}
              iconDescription={changedTitle !== title ? _t('save') : _t('cancel')}
              renderIcon={changedTitle !== title ? Save16 : Close16}
              onClick={submitButtonHandle}
            />
          </div>
          : (
            <>
              {isEditableTitleName
                ? (
                  <div
                    className="tree-view-item__top-with-edit-icon"
                    onMouseOver={() => iconOnHover(cellRef)}
                    onMouseOut={() => iconOnLeave(cellRef)}
                  >
                    <div className="tree-view-item__top-with-edit-icon-text">{changedTitle}</div>
                    <Edit16 style={{ visibility: 'hidden' }} ref={cellRef} />
                  </div>
                )
                : <div>{ changedTitle }</div>
              }
              {isDeleted && <Delete16 className="tree-view-item__top-remove-btn" onClick={onRemove} />}
            </>
          )
        }
      </div>
      <div className="tree-view-item__bottom">
      {isAuthType
      ? (
        <div className="tree-view-item__bottom-text">
          {parametrs}
        </div>
      )
      : (
        <ChangeValueDialogWindow 
            formConfig={formConfig}
            text={parametrs}
            initialValues={data}
            onUpdate={onUpdate}
            type={type}
            isEdit={isEdit}
            onClearValues={onClearValues}
            validation={validation}
          />
      )
      }
      </div>
    </div>
  )
}

export default PaymentOrchestrationTreeViewItem