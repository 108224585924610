import React from "react";
import { Grid, Row } from "carbon-components-react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FieldItem from "./FieldItem";
import FieldsArray from "./FieldsArray";
import { FieldsArrayWithDragDropProps } from "./types";

const FieldsArrayWithDragDrop = ({
  formik,
  name,
  formItemProps,
  components,
  error,
  onBlur,
  onChange,
  formProps,
  getNestedValue
}: FieldsArrayWithDragDropProps) => {
  let api: any;
  const useSensor = (value: any) => {
    api = value;
  };

  const startDrag = function start(itemId: string, isUp: boolean) {
    const preDrag = api.tryGetLock(itemId);
    if (!preDrag) {
      return;
    }
    const drag: any = preDrag.snapLift();
    isUp
      ? drag.moveUp()
      : drag.moveDown()

    drag.drop();
  }
  return (
    <FieldsArray
      name={name}
      values={formik?.values?.[name]}
      onFormChange={(name: any, value: any) =>
        formik.setFieldValue(name, value)
      }
      {...formItemProps}
    >
      <Grid condensed style={{ padding: "0 1rem" }}>
        <DragDropContext sensors={[useSensor]} onDragEnd={(result: any) => {
          if (result.destination) {
            const formArray = [ ...formik?.values?.[name] ]
            const element = formArray[result.source.index];
            formArray.splice(result.source.index, 1);
            formArray.splice(result.destination.index, 0, element);
            formik.setFieldValue(name, formArray)
          }

        }}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                role="menu"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {formik?.values?.[name] &&
                  formik?.values?.[name].map(
                    (item: any, arrayIndex: number) => {
                      const draggableIdentifikator = name + arrayIndex.toString()
                      return (
                        <Draggable
                          draggableId={draggableIdentifikator}
                          key={draggableIdentifikator}
                          index={arrayIndex}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            // style={getItemStyle(
                            //   snapshot.isDragging,
                            //   provided.draggableProps.style
                            // )}
                            >
                              <Row>
                                {components.map((item: any, index: number) => {
                                  let {
                                    component: Component,
                                    componentProps: formItemProps,
                                    name: componentName,
                                    columnParams = {},
                                    emptySpace,
                                  } = item;
                                  let fullName = !!componentName
                                    ? `${name}.${arrayIndex}.${componentName}`
                                    : `${name}.${arrayIndex}`;
                                  const componentProps =
                                    typeof formItemProps === "function"
                                      ? formItemProps(formik, arrayIndex)
                                      : { ...formItemProps };

                                  const removeItem = () => {
                                    const values = formik?.values?.[name];
                                    values.splice(arrayIndex, 1);
                                    formik.setFieldValue(name, values);
                                  }
                                  if (name === 'conditions' && componentName === 'operator' || Component.name === 'Divider') {
                                    fullName = 'operator'
                                    if (arrayIndex === formik?.values?.[name].length - 1 && index >= components.length - 2) {
                                      return
                                    } else if (arrayIndex && arrayIndex === formik?.values?.[name].length - 2) {
                                      delete componentProps.valueA
                                      delete componentProps.valueB
                                    }
                                  }

                                  let additionalProps: any = {}

                                  switch (item.name) {
                                    case 'removeBtn':
                                      additionalProps = { onClick: removeItem };
                                      break;
                                    case 'reorderArrows':
                                      additionalProps = {
                                        onMoveUp: () => startDrag(draggableIdentifikator, true),
                                        onMoveDown: () => startDrag(draggableIdentifikator, false),
                                      }
                                      break;
                                    case 'dragDropBtn':
                                      additionalProps = { ...provided.dragHandleProps }
                                      break;
                                      default: 
                                      additionalProps = {}
                                  }
                                  return (
                                    <FieldItem
                                      emptySpace={emptySpace}
                                      columnParams={columnParams}
                                      Component={Component}
                                      additionalProps={additionalProps}
                                      name={fullName}
                                      componentProps={componentProps}
                                      error={error(name)?.[arrayIndex]}
                                      onBlur={onBlur}
                                      onChange={onChange}
                                      formik={formik}
                                      formProps={formProps}
                                      getNestedValue={getNestedValue}
                                      onRemove={name !== 'depositSettings' && removeItem}
                                    />
                                  );
                                })}
                              </Row>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}

                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </FieldsArray>
  );

};

export default FieldsArrayWithDragDrop;
